import React, {useState, useEffect, useCallback} from 'react';
import {throttle} from 'lodash'
import styled from 'styled-components';
import Layout from "../components/layout";
import SectionFooter from '../components/sections/section-footer/SectionFooter';
import RowWrapper from '../utils/rowWrapper';
import Explore from '../utils/explore';
import ProgressBar from '../utils/progressBar-step';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import Slide from '../components/couches-slides/slide';
import '../css/couches.scss';
import { theme } from "../utils/theme"

import {useSwipeable} from 'react-swipeable';

import context from "../context";



gsap.registerPlugin(ScrollToPlugin);


const Container = styled.div`
  /* height: 100vh; */
  width: 100%;
  background-color: ${props => props.theme.colors.body};
  position: relative;
  /* overflow-y: hidden; */
`

const LandingContent = styled.div`
 height: 100vh;
 position: relative;
`

const Center = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;

  @media ${props => props.theme.breaks.down('md')} {
    width: 90%;
  }
`

const Title = styled.h1`
  font-size: 7rem;
  font-weight: 300;
  margin-bottom: 20px;

  @media ${props => props.theme.breaks.down('md')} {
    font-size: 5rem;
    text-align: center;
  }
`

const PbContainer = styled.div`
  position: fixed;
  bottom: 80px;
  right: 12%;
  width: 30%;
  z-index: 80;

  @media ${props => props.theme.breaks.down('md')} {
    bottom: 30%;
    width: 60%;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    bottom: 30%;
    width: 60%;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
`

const FixedFooter = styled.div`
  width: 100%;
  /* min-width: ${props => props.theme.maxWidthMd};
  max-width: 1400px; */
  position: fixed;
  /* left: 50%;
  transform: translateX(-50%); */
  height: 100px;
  padding-left: 40px;
  bottom: 20px;

  @media ${props => props.theme.breaks.down('md')} {
    padding-left: 0;
    bottom: 0;
  }
`

let number = 0;

  
const CouchPage = ({ data }) => {

  const [counter, setCounter] = useState(0);

  const scroll = (num, e) => {
    // if (!context.couchAnimate) {
    //   console.log('hello..........');
    //   const x = window.scrollX;
    //   const y = window.scrollY;
    //   console.log(y, x);
    //   return window.scrollTo(x, y);
    // //  e.preventDefault()
    // //  return
    // }
    if (!e) {
      gsap.to(window, {scrollTo: '.sec-1', duration: .6})
      setCounter(x => x + 1)
      number = 1
    } else {
      context.couchAnimate = true;
      if ((!e.dir && e.deltaY > 10) || (e.dir && e.dir === "Up")) {
        if (num < 5) {
          gsap.to(window, {scrollTo: `.sec-${num + 1}`, duration: .6, onComplete: () => context.couchAnimate = false})
          setCounter(x => x + 1)
          num = Math.min(num + 1, 5)
        } 
      } else if ((!e.dir && e.deltaY < -10) || (e.dir && e.dir === "Down")) {
        if (num > 0) {
          gsap.to(window, {scrollTo: `.sec-${num - 1}`, duration: .6, onComplete: () => context.couchAnimate = false})
          setCounter(x => x - 1)
          num = Math.max(num - 1, 0)
        } 
      }
    }

    // console.log("here.......");
    return num;
  }

  const scroller = useCallback(throttle((e) => {
    number = scroll(number, e)

  }, 800, {trailing: false}), [])

  const {ref, onMouseDown} = useSwipeable({
    onSwipedUp: scroller,
    onSwipedDown: scroller,
    trackMouse: true,
    preventDefaultTouchmoveEvent: true
  });
  

  useEffect(() => {

    window.scrollTo(0,0)
  
    window.addEventListener("wheel", scroller)
    
    ref(document)
    document.addEventListener('mousedown', onMouseDown)

    return () => {
      window.removeEventListener("wheel", scroller)
      document.removeEventListener('mousedown', onMouseDown)
      ref()
    }

  }, [])

  // const {HeroImage, Heading1, Heading2} = data.strapiHero;
  return (
    
      <Layout pageTitle="A couch is more than just a couch">
      <Container>
        <LandingContent className="sec-0">
          <Center>
            <Title>A couch is more than just a couch.</Title>
            <Explore click={() => scroll()} sec="couch" sub="1" show top="65%" left="45%" />
          </Center>
        </LandingContent>

       <Slide section="sec-1" image="https://res.cloudinary.com/celebrand/image/upload/v1618279966/couches1_nzcm7w.jpg" title="It's a comfort bench." />

       <Slide section="sec-2" image="https://res.cloudinary.com/celebrand/image/upload/v1618279966/couches2_mezrl6.jpg" title="It's a statement piece." />

       <Slide section="sec-3" image="https://res.cloudinary.com/celebrand/image/upload/v1618279968/couches3_ukboek.jpg" title="It's a welcome sign." />

       <Slide section="sec-4" image="https://res.cloudinary.com/celebrand/image/upload/v1618279969/couches4_sxasqn.jpg" title="and a business can be more than just a business." />

       <Slide section="sec-5" image="https://res.cloudinary.com/celebrand/image/upload/v1618279969/couches5_fac1c6.jpg" title="that's branding. see how easy it is?" />

       <PbContainer>
        <ProgressBar count={5} current={counter} color={theme.colors.dark}>&nbsp;</ProgressBar>
       </PbContainer>

         <FixedFooter>
          <SectionFooter></SectionFooter>
          
         </FixedFooter>
        
      </Container>
      </Layout>
    
)
}

export default CouchPage;