import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 2px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  opacity: ${props => props.cur === 0 ? 0 : 1};
  transition: all .6s ease-out;

  .before, .after {
    position: absolute;
    top: -10px;
  }

  .before {
    left: -40px;
  }

  .after {
    right: -40px;
  }

  .partOfBar {
    height: 100%;
    width: calc(100% / ${props => props.cur});
  }
`
 
export default ({count, current, bg, color}) => {

  const numOfDivs = [...Array(count)].map((cur, i) => <div className="partOfBar" key={i} style={{backgroundColor: i === current - 1 ? color : 'transparent'}}>&nbsp;</div>);


 return (
  <Container count={count} cur={current} bg={bg} color={color}>
    <div className="before">0{current}.</div>
    {numOfDivs}
    <div className="after">0{count}.</div>
  </Container>
 )
}