import React from 'react';
import styled from 'styled-components';

import RowWrapper from '../../utils/rowWrapper';


const Container = styled.div`
 height: 100vh;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 75%;
  margin: 0 auto;
  height: 100vh;
  position: relative;

  @media ${props => props.theme.breaks.down('md')} {
  flex-direction: column;
  width: 100%;
  }
`

const Image = styled.div`
  height: 100%;
  background-image: url(${props => props.src});
  background-position: center;
  background-size: cover;
  width: 50%;
  flex-shrink: 0;

  @media ${props => props.theme.breaks.down('md')} {
    width: 100%;
    height: 50%;
    margin-top: 20%;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    width: 100%;
    height: 40%;
    margin-top: 45%;
  }
`

const TitleWrapper = styled.div`
  padding-left: 70px;
  flex-shrink: 1;

  @media ${props => props.theme.breaks.down('sm')} {
  padding-left: 0;
  }
`

const Caption = styled.h3`
  font-weight: 300;
  font-size: 2.5rem;
  position: absolute;
  top: 5rem;

  @media ${props => props.theme.breaks.down('md')} {
    top: 8rem;
    left: 10%;
  }
`

const Title = styled.h1`
  font-size: 7rem;
  font-weight: 300;

  @media ${props => props.theme.breaks.down('md')} {
    font-size: 3rem;
    margin-top: 40px;
    margin-left: 40%;
    padding-right: 20px;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    font-size: 2.5rem;
  }

  /* width: 50%; */
`
 
export default ({image, title, section}) => {
 
 return (
   <Container>
     <Content className={section}>
      <Image src={image}> &nbsp;</Image>
      <TitleWrapper>
        <Caption>A couch is more <br /> than just a couch.</Caption>
        <Title>{title}</Title>
      </TitleWrapper>
     </Content>
   </Container>
 )
}

